import { storeToRefs } from 'pinia'
import { useRoot } from '~/store/'
import { useRadar } from '~/store/radar'
import { Layout } from '~/types/custom'
import type { Theme } from '~/types/theme'

export default defineNuxtRouteMiddleware((to, from) => {
  const root = useRoot()

  const radar = useRadar()
  const { routes } = radar

  const { setTheme, themes } = root

  const { theme } = storeToRefs(root)

  if (routes.some((route: { name: string | undefined }) => route?.name === to?.name?.toString()?.split('___')[0])) {
    setPageLayout(Layout.default)
  } else {
    setPageLayout(Layout.show)
  }

  const currentTheme = from?.query?.theme
  const targetTheme = to?.query?.theme

  const queryTheme: Theme | undefined = themes.find((theme: Theme) => theme.code === from?.query?.theme)

  if (queryTheme) setTheme(queryTheme, false)

  const { query } = to

  if (!targetTheme) {
    if (currentTheme) query.theme = currentTheme

    if (theme.value?.code) query.theme = theme.value.code
  }
})
