/**
 * exporting static locales list because useI18n().availableLocales returns only current theme when lazy loading is enabled
 */

import type { LocaleObject } from 'vue-i18n-routing'
import { CountryFlag } from '../types/custom'
import type { Flag } from '../types/assets'

export const locales: LocaleObject[] = [
  {
    code: 'cn',
    file: 'cn.json',
    language: 'zh-CN'
  },
  {
    code: 'es',
    file: 'es.json',
    language: 'es-ES'
  },
  {
    code: 'fr',
    file: 'fr.json',
    language: 'fr-FR'
  },
  {
    code: 'in',
    file: 'in.json',
    language: 'hi'
  },
  {
    code: 'en',
    file: 'en.json',
    language: 'en-US'
  },
  {
    code: 'it',
    file: 'it.json',
    language: 'it-IT'
  },
  {
    code: 'jp',
    file: 'jp.json',
    language: 'ja-JP'
  },
  {
    code: 'ru',
    file: 'ru.json',
    language: 'ru-RU'
  }
]

export const localeCodes: string[] = locales.map((l) => l.code)

export const localeFlags: Flag[] = localeCodes.map(
  (locale: string): Flag => ({
    countryCode: locale,
    src: CountryFlag[locale as keyof typeof CountryFlag]
  })
)
